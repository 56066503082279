import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link, BrowserRouter
} from "react-router-dom";
import Home from "./view/Home";
import Terms from "./view/Terms";

const App = () => {

    return (
        <BrowserRouter>
            <Switch>
                <Route path="/terms">
                    <Terms/>
                </Route>
                <Route path="/">
                    <Home/>
                </Route>

            </Switch>

        </BrowserRouter>
    )
}

export default App
