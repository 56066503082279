
const Terms = () => {
    return (
        <div className='container' style={{marginTop:30}}>
            <p><strong>Privacy Notice</strong></p>
            <p>&nbsp;</p>
            <p>We acknowledge that protecting personal information is very important to all of our users. We always respect and do our best to protect our user&rsquo; privacy and personal information and we take this matter very seriously. We assure you that we comply with the obligations that are given under data protection laws.</p>
            <p>&nbsp;</p>
            <p>This privacy notice states how we, as a data controller, will use and protect your information. We will use your personal information only for the purposes and in the manner stated in this privacy notice.</p>
            <p>&nbsp;</p>
            <ol>
                <li><strong> About us</strong></li>
            </ol>
            <p>The associated &nbsp;&ldquo;Zad halal&rdquo; mobile application is operated by Telisoft company. We are registered in South Korea with registered number 304-31-00323 and our registered office at Office no. 4275, NH Capital building, 27-8, Gukjegeumyung-ro 8-gil, Yeongdeungpo-gu, Seoul, Republic of Korea.</p>
            <p>&nbsp;</p>
            <ol start="2">
                <li><strong> How to get in touch with &ldquo;Zad Halal&rdquo;?</strong></li>
            </ol>
            <p>If you have any questions about this privacy notice or any other questions or issues, our customer care team is here to help &ndash; please contact us via:<br /><br />Email: telisoft24@gmail.com</p>
            <p><strong>&nbsp;</strong></p>
            <ol start="3">
                <li><strong> What information does Zad Halal collect?</strong></li>
            </ol>
            <p>We may collect and process the following information about you:</p>
            <p><strong>Contact Details</strong> : name; email address; telephone number; social media ID;</p>
            <p><strong>Payment Details</strong> : cardholder name; credit or debit card details (however, we only retain certain parts); chargeback information; billing address (in limited circumstances);</p>
            <p><strong>Delivery Details</strong>: Address, phone number and receiver name</p>
            <p><strong>Electronic Identifying Details</strong> : IP address; SMS content; cookies; pixels; activity logs (e.g. user session recordings); online identifiers; device identifiers (e.g. the mobile device and/or the internet browser that you use) and geolocation data;</p>
            <p><strong>Correspondence and complaints</strong>: any correspondence that you choose to send to ZadHalal (including complaints); any information you provide to our customer care team; any information you publish about ZadHalal (including on the app stores);</p>
            <p>If you choose to grant the mobile applications access to contact information through your device, we may collect this information, including names, telephone numbers, email addresses and social media identifiers. We use this information to help us deliver the Services to your family and friends in the most efficient manner and for the other purposes listed below.</p>
            <p>&nbsp;</p>
            <ol start="5">
                <li><strong> How does Zadhalal collect your personal data?</strong></li>
            </ol>
            <p><strong>&nbsp;</strong></p>
            <p>You provide us with your personal data when you register for, or use our Services. We may also collect personal data about you from third parties (e.g. in the event of chargebacks or through third party direct marketing services). We also collect information about you through your use of our Applications, your interactions with our customer care team and the transactions you carry out on our mobile application and website. When you visit our mobile application or website, your device and/or browser may automatically disclose certain information (such as device type, operating system, browser type, browser settings, IP address, language settings, dates and times of connection to an Application or other technical communications information), some of which may constitute personal data.</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <ol start="6">
                <li><strong> How long do we keep your personal data?</strong></li>
            </ol>
            <p>We will keep your personal data only for so long as is necessary to carry out the purposes set out above and to comply with any legal obligations.</p>
            <p>&nbsp;</p>
            <ol start="7">
                <li><strong> Where does ZadHalal hold your personal data?</strong></li>
            </ol>
            <p>&nbsp;</p>
            <p>Your personal data is kept by us on our secure servers which are hosted by Zadhalal or by a third party hosting infrastructure company.</p>
            <p>&nbsp;</p>
            <ol start="8">
                <li><strong> Changes to this privacy notice</strong></li>
            </ol>
            <p>We may update this privacy notice from time to time. If we make any material changes, we will notify you by posting a notice on our Mobile Application, website and/or, where appropriate, sending you a notification.</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p><strong>Terms &amp; Conditions</strong></p>
            <p>&nbsp;</p>
            <ol>
                <li><strong> Registration</strong></li>
            </ol>
            <p>To use the Services, you may elect to register for an Account. You can do this by filling out the appropriate information on the registration screen on the relevant mobile application or website. Upon registration you will be given an Account. When registering you may supply personal information, including your email address or phone number and financial information (&ldquo;Data&rdquo;). You may also choose a password.</p>
            <p>You are entirely responsible for all activities which occur when using your email address, phone number and/or password (&ldquo;Login Details&rdquo;) in relation to your Account, including unauthorized use of your or any other payment method including debit or credit card. You must not disclose your password, whether directly or indirectly, to any third party. It is your responsibility to safeguard your password. <br /><br />If you are under 16 years of age, you must obtain a parent/guardian's consent prior to using the mobile application and website. If you are an employee of a company or other entity or are acting on behalf of a company or entity, you must be authorized to accept this Agreement on behalf of that employer, company or entity.</p>
            <p>&nbsp;</p>
            <ol start="2">
                <li><strong> Use of Services </strong></li>
            </ol>
            <p>By using &ldquo;Zad Halal&rdquo; mobile application, you are fully aware that the products are provided by the listed provider, and it&rsquo;s delivered to you directly from the correspondent provider.</p>
            <p><strong>Zad Halal</strong> is a platform hosts other providers&rsquo; products, and we don&rsquo;t own or hold any liability of these products.</p>
            <p>We are at Zad Halal we will do our best to provider a high-quality services to our users, and finding the best way to connect</p>
            <p>Zad halal may block or terminate your account temporary or permanently if we find your account suspicious of fraud or involved in illegal activity in connection with Zad Halal app or website.</p>
            <p>&nbsp;</p>
            <ol start="3">
                <li><strong> Ordering Process</strong></li>
            </ol>
            <p>When placing an order, you agree that any and all information you give us is accurate and complete.</p>
            <p>&nbsp;</p>
            <p>All orders are subject to acceptance and product availability. All prices listed on the Zad Halal app or website are correct at the time of entering the information, however, Zad Halal reserves the right to change prices of any product at any time. The total price of your order, including any sales taxes, will be displayed prior to your purchase confirmation.</p>
            <p>Zad Halal Foods can cancel your order or refuse to process your purchase any time it finds needed.</p>
            <p>&nbsp;</p>
            <ol start="4">
                <li><strong> Your Obligations</strong></li>
            </ol>
            <p>You can download the mobile applications from the app stores free of charge. <strong>Zad Halal</strong> reserves the right to modify equipment and software requirements as is necessary for it to continue or improve the provision of Services through the mobile applications. You are solely responsible for ensuring that you download any subsequent updates to the mobile applications from the relevant app store.</p>
            <ol start="5">
                <li><strong> Security, Maintenance and Availability</strong></li>
            </ol>
            <p>You agree, acknowledge and accept that electronic communications, the internet, telephone lines or SMS-based telecommunications media may not be secure and communications via such media may be intercepted by unauthorized persons or delivered incorrectly. As a consequence, <strong>Zad Halal</strong> cannot guarantee the privacy or confidentiality of communications via such media although it will (and shall procure that its service providers will) put in place appropriate security measures to protect these methods of communications.</p>
            <p>&nbsp;</p>
            <p>From time to time it may be necessary to or desirable for security reasons, maintenance (whether emergency or planned), upgrades or other reasons to:</p>
            <p>&nbsp;</p>
            <ul>
                <li>make certain or all of the mobile application, website or Services unavailable to you; and/or</li>
                <li>delay implementation of any new Services; and/or</li>
                <li>withdraw, replace or reissue your password; and/or</li>
                <li>change authentication procedures or processes for accessing the Applications or the Services,</li>
            </ul>
            <p>while using reasonable endeavors to minimize any inconvenience caused.</p>
            <p>&nbsp;</p>
            <ol start="6">
                <li><strong> Governing Law</strong></li>
            </ol>
            <p>This Agreement, the Applications and the provision of Services will be governed by the laws of the Republic of Korea. If any claim or dispute arises from, out of or in connection with this Agreement and/or your use of the mobile application, website or any Services, you agree that the courts of the Republic of Korea will have exclusive jurisdiction over all such claims or disputes, without prejudice to your rights under applicable legislation.</p>
            <p>&nbsp;</p>
            <ol start="7">
                <li><strong> Customer Care &amp; Contact Information</strong></li>
            </ol>
            <p>If you have any questions about this Agreement or any complaints, or require any assistance with the Applications or the Services you have purchased, we are always here to help</p>
            <p>Telisoft24@gmail.com</p>
            <p>&copy; Zadhalal 2021, offered by Telisoft</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p><strong>Refund policy</strong></p>
            <p><strong>&nbsp;</strong></p>
            <p><strong>Refund is applicable:</strong></p>
            <ul>
                <li>The product(s) is not the same as your order</li>
                <li>The product is broken or spoiled</li>
                <li>The package is broken</li>
            </ul>
            <p>If any the reason is not any of the above, you may contact us and to find out if your eligible for (partial) or (full) refund in not more than a 2 business days.</p>
            <p>&nbsp;</p>
            <p>If you would like to refund the money in your Zad halal account you find your reason the same as above ones, your money will be refunded within 2 business days, however if you cannot find any reason given above, you may contact us at <a href="mailto:telisoft24@gmail.com">telisoft24@gmail.com</a> and explain the reason. If our legal team finds your reason eligible for refund, you will get the refund within a 2-3 business day. <strong><br /></strong><br />* If you want to terminate your Zad halal account while having refundable money in your account, we advise you to contact us first before terminating.</p>
            <p><br /><br /><strong>Late or missing refunds:</strong></p>
            <p><br />-If you have not received a refund yet, first check your bank account again.<br />-Contact your credit card company, it may take some time before your refund is officially posted.</p>
            <p>-Contact your bank. There is often some processing time before a refund is posted.</p>
            <p>-If you have done all of this and you still have not received your refund yet, please contact us &nbsp;at <a href="mailto:telisoft24@gmail.com">telisoft24@gmail.com</a>.<strong> <br /><br /></strong></p>
        </div>
    )
}

export default Terms
